import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../styles/header.css";
import { Container, Navbar, Button } from "react-bootstrap";
import AuthService from "../services/AuthService";
const Header = ({ topBanner }) => {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 2838386, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    let today = new Date();
    let currentLocalTime = today.toLocaleTimeString();

    const currentToken = AuthService.getCurrentToken();
    console.log(
      "currentToken Expiration",
      currentToken.expiration,
      currentLocalTime,
    );
    if (currentToken.expiration > currentToken.expiration) {
      console.log("token expired");
      AuthService.logout();
      history.push("/logg-inn");
    } else if (currentLocalTime > currentToken.expiration) {
      AuthService.refreshAccessToken(currentToken.refresh_token).then(
        (response) => {
          console.log(response);
          setAuthenticated(true);
          history.push("/min-side");
          //window.location.reload();
        },
        (error) => {
          console.log(error);
          history.push("/logg-inn");
        },
      );
    } else {
      console.log("token is active");
    }
    if (currentToken) {
      setAuthenticated(true);
    }
  }, []);

  let history = useHistory();
  const handleClick = (linkReference) => {
    switch (linkReference) {
      case "kundeservice":
        window.location.href = "https://fabel.no/kundeservice/";
        break;
      case "registrering":
        window.location.href =
          "https://fabel.no/#frontpage-subscriptions-section";
        break;
      case "log-out":
        AuthService.logout();
        //window.location.href = '/'
        //window.location.replace(
        // process.env.REACT_APP_FABEL_WP_URL + "SSO/sso_login_v2.php?url=" + process.env.REACT_APP_FABEL_WP_URL
        //);
        window.location.href = "/logg-inn";
        //history.push("logg-inn");
        break;
      case "min-side":
        window.location.reload();
        break;
      default:
        break;
    }
  };
  return (
    <>
      {topBanner ? <div className="top-banner">{topBanner}</div> : null}
      <Container className="header-container">
        <Navbar expand="lg" className="header-nav">
          <div>
            <a href="https://fabel.no">
              <img
                src="https://filer.fabel.no/web-images/uploads/2019/01/fabel-logo-RGB.png"
                alt=""
              />
            </a>
          </div>
          <div className="header-hamburger-meny">&#9776;</div>
          <div className="navigation-links">
            <div className="menu">
              <Button
                variant="link"
                className="btn-link"
                onClick={() => handleClick("kundeservice")}
              >
                KUNDESERVICE
              </Button>
              {authenticated ? (
                <Button
                  variant="link"
                  className="btn-link"
                  onClick={() => handleClick("log-out")}
                >
                  LOGG UT
                </Button>
              ) : (
                <Button
                  variant="link"
                  className="btn-link"
                  onClick={() => handleClick("registrering")}
                >
                  REGISTRERING
                </Button>
              )}
            </div>
          </div>
        </Navbar>
      </Container>
    </>
  );
};

export default Header;
